import dayjs from 'dayjs';
import { DictionaryId, ExchangePublication, JSONAble } from './ordersFromApi';
import { Employee } from './employees';
import { NumberWithPrefix } from './contacts';
import { CompanyId } from './user';
import { Client } from './clients';

export enum PaymentTypes {
  TRANSFER = 'transfer',
  DEFFERED = 'document_delivery_date',
  PAYMENT_IN_ADVANCE = 'loading_date',
  PAYMENT_ON_UNLOADING = 'unloading_date',
}

export enum OrderStopType {
  LOADING = 'LOADING',
  UNLOADING = 'UNLOADING',
  OTHER = 'OTHER',
  CUSTOMS = 'CUSTOMS',
  TRAILER_HANDOVER = 'TRAILER_HANDOVER',
  TRAILER_PICKUP = 'TRAILER_PICKUP',
  PALLET_RETURN = 'PALLET_RETURN',
}

export interface PaymentI {
  type?: PaymentTypes;
  numberOfDays?: number;
}

export enum OriginTypes {
  EMAIL = 'email',
  WEB = 'web',
}

export enum OriginNames {
  UserFile = 'user-file',
  UserMail = 'user-mail',
}

export enum Unit {
  METER = 'm',
  KILOGRAM = 'kg',
  LITER = 'l',
  COUNTABLE = 'c',
  OTHER = 'other',
}

export enum PaymentConditions {
  FROM_START = 'fromStart',
  FROM_UNLOADING = 'fromUnloading',
  FROM_UNLOADING_AFTER = 'fromUnloadingAfter',
}

export enum OrderStatus {
  INITIAL = 'initial',
  DRAFT = 'draft',
  CANCELLED = 'cancelled',
  IN_PROGRESS = 'in-progress',
  DELETED = 'deleted',
  OTHER = 'other',
  ERROR = 'error',
  COMPLETED = 'completed',
  READY_TO_PUBLISH = 'ready_to_publish',
  PUBLISHED = 'published',
  NEW = 'new',
  IN_TMS = 'in_tms',
}

export type OrderStatusId = string;
export type OrderStatusT = {
  uuid: OrderStatusId;
  name: string;
  color: string | null;
  defaultAction?: 'EDIT';
  company?: CompanyId;
  priority?: number;
  isImmutable?: boolean;
};

export type Trailer = {
  uuid: string;
  name: string;
  registrationNumber: string;
  gpsInfo: string;
  type: string;
};

export type Vehicle = {
  uuid: string;
  name: string;
  registrationNumber: string;
  gpsInfo: string;
  type: string;
  trailers: Trailer[];
};

export type Driver = {
  uuid: string;
  name: string;
  surname: string;
};

export type Address = {
  uuid?: string;
  city: string;
  country: string;
  postalCode: string;
  street?: string;
  lat?: number;
  lng?: number;
};

export type Cargo = {
  id?: string;
  uuid?: string;
  number?: number;
  name?: string;
  type?: DictionaryId | null;
  ref?: string;
  quantity?: number;
  unit?: Unit;
  height?: number;
  width?: number;
  length?: number;
  volume?: number;
  weight?: number;
  loadingMeters?: number;
  description?: string;
  palletExchangeRequired: boolean;
  extraData?: {
    quantityClient?: number;
    quantityCarrier?: number;
    paletteName?: string;
  } & Record<string, unknown>;
};

export type CargoRef = {
  number?: number;
  quantity?: number;
};

export type Spot = {
  uuid?: string;
  name?: string;
  loadingNumber?: string;
  type: OrderStopType;
  otherType?: OrderStopType;
  dateFrom: DateTime;
  dateTo: DateTime;
  additionalInfo?: string;
  address: Omit<Address, 'street'>;
  addressStreet?: string;
  cargo: CargoRef[];
  cargoRef?: string[];
  addressAccordingToCMR: boolean;
};

export type Origin = {
  uuid?: string;
  name: OriginNames;
  type?: OriginTypes;
  orderNumber?: string;
  data?: {
    email?: MailMessage;
    file?: {
      name?: string;
    };
  };
};

export type DateTime = {
  date?: dayjs.Dayjs;
  time?: dayjs.Dayjs;
};

export type VehicleRequirements = {
  capacity?: number;
  loadingMeters?: number;
  description?: string;
  otherRequirements?: string;
  waysOfLoading: string[];
  vehiclesSize: string[];
  bodyTypes?: string[];
  isFtl: boolean;
  adrs: string[];
  hasAdrs: boolean;
};

export type PriceData = {
  value?: number;
  currency?: string;
  paymentType?: PaymentTypes;
  paymentDelayInDays?: number | null;
  paymentConditions?: PaymentTypes | null;
};

export type ContactPerson = {
  id?: string;
  displayName?: string;
  email?: string;
  phone?: NumberWithPrefix;
};

export type OrderClient = {
  id?: string;
  displayName?: string;
  nip?: string;
  address: Address;
  clientIsNew?: boolean;
  clientContactIsNew?: boolean;
  isBlocked?: boolean;
};

export type FileData = {
  id: string;
  name: string;
  url: string;
  path: string;
  type: string;
  extension: string;
};

export type Carrier = {
  clientId?: string | null;
  client?: (Omit<Partial<Client>, 'nip'> & { nip?: string }) | null;
  contactId?: string | null;
  price?: string | null;
  currencyId?: string | null;
  paymentMethod?: string | null;
  paymentInDays?: number | null;
  paymentPeriodStartingPoint?: string | null;
  vehicleRegistrationNumber?: string | null;
  trailerRegistrationNumber?: string | null;
  driverFirstName?: string | null;
  driverLastName?: string | null;
  driverIdentityDocumentNumber?: string | null;
  driverPhone?: NumberWithPrefix | null;
};

export type CarrierToApi = Carrier;

export interface Order {
  uuid?: string;
  origin: Origin;
  comments?: string;
  customStatus?: OrderStatusT;
  defaultAction?: string;
  isAiProcessing?: boolean;
  createdBy?: string;
  orderNumber?: string | null;
  isDuplicated?: boolean;
  createdAt: DateTime;
  orderDate: DateTime;
  carrier?: Carrier;
  price: PriceData;
  responsiblePerson?: Employee['id'] | null;
  seller?: Employee['id'] | null;
  client: OrderClient;
  contactPerson: ContactPerson;
  firstLoadingPlace?: Spot;
  lastUnloadingPlace?: Spot;
  stops?: Spot[];
  vehicleRequirements: VehicleRequirements;
  extraData: Record<string, unknown> | JSONAble;
  file?: FileData;
  files?: FileData[];
  cargo: Cargo[];
  sources: string[];
  payment?: PaymentI;
  exchangePublications?: ExchangePublication[];
}

export type OrderParams = {
  limit: number;
  isLazyLoad: boolean;
};

export type OrderFilters = {
  statuses?: string[];
  responsiblePeople?: string[];
};

export interface OrderPublication extends Omit<Order, 'contactPerson'> {
  contactPerson: string;
}

export type MailMessageAttachment = {
  id: string;
  name: string;
  url: string;
};

export type MailMessage = {
  id: string;
  subject: string;
  content: string;
  fromEmail: string;
  fromDisplayName: string;
  toEmail: string;
  toDisplayName: string;
  createdAt: string;
  attachments: MailMessageAttachment[];
};

interface EmployeeStatsData {
  name: string;
  data: number[];
}

interface ClientStatsData {
  clientName: string;
  offers: number;
}

interface StatusStatsData {
  [key: string]: number;
}

export interface OrdersStatsData {
  year: number;
  currentMonthAmount: number;
  currentYearCompletedAmount: number;
  currentYearUnfulfilled: number;
  offersToday: number;
  offersAcceptedToday: number;
  offersRejectedToday: number;
  currentYearAveragePerMonth: number[];
  currentYearEmployeePerMonth: EmployeeStatsData[];
  perStatus: StatusStatsData;
  topClients: ClientStatsData[];
}
