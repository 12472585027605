export const CLIENTS_CREATE = 'Clients:create';
export const CLIENTS_UPDATE = 'Clients:update';
export const CLIENTS_REMOVE = 'Clients:remove';
export const CLIENTS_SEARCH = 'Clients:search';
export const CLIENTS_FETCH_LIST = 'Clients:fetch.list';
export const CLIENTS_FETCH_DETAILS = 'Clients:fetch.details';
export const CLIENTS_FETCH_OPTIONS = 'Clients:fetch.options';
export const CLIENTS_SET_PAGE = 'Clients:set.page';

export const CLIENTS_OPEN_FORM = 'Clients:form.open';
export const CLIENTS_CREATE_SUCCESS = 'Clients:create.success';
export const CLIENTS_UPDATE_SUCCESS = 'Clients:update.success';
export const CLIENTS_OPEN_DETAILS = 'Clients:details.open';

// WEBSOCKET EVENTS
export const CLIENTS_CREATED = 'Clients:created';
export const CLIENTS_REMOVED = 'Clients:removed';
export const CLIENTS_UPDATED = 'Clients:updated';
