import { JSONable } from '@frontend-composer/core';
import {
  CLIENTS_FETCH_LIST,
  CLIENTS_OPEN_DETAILS,
  CLIENTS_OPEN_FORM,
  CLIENTS_REMOVE,
  CLIENTS_SET_PAGE,
} from '@/eventrixEvents/clients';
import { GenericListModuleData } from '@/modules/GenericList/types';

const clientsListModuleData: JSONable = {
  title: 'clients',
  baseStateName: 'clients',
  events: {
    loadList: CLIENTS_FETCH_LIST,
    setPage: CLIENTS_SET_PAGE,
    onRowClick: CLIENTS_OPEN_DETAILS,
  },
  translationContext: 'clients',
  limit: 20,
  filtersModule: 'clientsFilters',
  headerActions: [
    {
      title: 'add',
      iconName: 'eva:plus-outline',
      text: 'add',
      eventName: CLIENTS_OPEN_FORM,
    },
  ],
  listColumns: [
    {
      id: 'companyName',
      title: 'companyName',
      key: 'companyName',
      component: 'clientName',
      sx: {
        maxWidth: 250,
        minWidth: 250,
        ['& > *']: {
          maxWidth: '100%',
        },
      },
    },
    {
      id: 'address',
      title: 'address',
      key: 'address',
      component: 'address',
      sx: {
        maxWidth: 220,
        minWidth: 220,
      },
    },
    {
      id: 'nip',
      title: 'nip',
      key: 'nip',
      component: 'clientNip',
      sx: {
        maxWidth: 160,
        minWidth: 160,
      },
    },
    {
      id: 'phone',
      title: 'phone',
      key: 'phone',
      component: 'phone',
      sx: {
        maxWidth: 160,
        minWidth: 160,
      },
    },
    {
      id: 'email',
      title: 'email',
      key: 'email',
      component: 'email',
      sx: {
        maxWidth: 200,
        minWidth: 200,
      },
    },
    {
      id: 'types',
      title: 'type',
      key: 'types',
      align: 'right',
      component: 'clientTypes',
      sx: {
        maxWidth: 192,
        minWidth: 192,
      },
    },
  ],
  actionsColumnWidth: 106,
  listItemActions: [
    {
      iconName: 'mingcute:pencil-2-line',
      title: 'edit',
      eventName: CLIENTS_OPEN_FORM,
    },
    {
      iconName: 'ph:trash',
      title: 'remove',
      eventName: CLIENTS_REMOVE,
      confirm: {
        title: 'removeClient',
        content: 'removeClientDescription',
      },
    },
  ],
  listMainActions: [
    {
      iconName: 'mingcute:pencil-2-line',
      title: 'edit',
      eventName: CLIENTS_OPEN_FORM,
    },
  ],
  mergeActionsToDropdown: true,
  showCursorPointer: true,
  tablePaddingBottom: 216,
} satisfies GenericListModuleData;

export default clientsListModuleData;
