import { IntegrationStatus } from './integrations';

export enum TmsName {
  FIRETMS = 'FIRETMS',
  ZOHO = 'ZOHO',
}

export enum TmsStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface TmsI {
  uuid: string;
  icon: string;
  fullIcon: string | null;
  disabledIcon?: string;
  name: TmsName;
  displayName: string;
  isApiAvailable: boolean;
  status: IntegrationStatus;
  integrationEventName: string;
  removeIntegrationEventName: string;
  refreshIntegrationEventName: string;
  lastOperationError: string | null;
  href?: string;
  error?: string;
  items?: any[];
}

export interface FetchedTmsI {
  lastOperationError: string | null;
  name: TmsName;
  status: TmsStatus;
}

export interface TmsSettings {
  type: TmsName | null;
}
