import { LanguageOption } from '@/types/languageOption';

export const SUPPORTED_LANGUAGES = [
  {
    value: 'en',
    label: 'languages.english',
    icon: '/assets/icons/ic_flag_en.svg',
  },
  {
    value: 'pl',
    label: 'languages.polish',
    icon: '/assets/icons/ic_flag_pl.svg',
  },
] satisfies LanguageOption[];

export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES[0].value;
