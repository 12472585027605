import { ComposerConfig, ResourceLoader } from '@frontend-composer/core';

// layouts
import loadBasicLayout from '@/layouts/Basic';
import loadLoginLayout from '@/layouts/Login';
import loadFullViewLayout from '@/layouts/FullView';
import loadOutlookLayout from '@/layouts/Outlook';

// modules
import loadOutlookWelcomeModule from '@/modules/OutlookWelcomeModule';
import loadSendEmailModule from '@/modules/SendEmailModule';
import loadFirebaseFilesStorage from '@/services/FileService/FirebaseFilesStorage';
import loadDictionariesService from '@/services/DictionariesService';
import loadRootModule from '@/modules/RootModule';
import loadModalsModule from '@/modules/ModalsModule';
import loadThemeProviderModule from '@/modules/ThemeProviderModule';
import loadLogoModule from '@/modules/Logo';
import loadNavigationModule from '@/modules/Navigation';
import loadAlertsModule from '@/modules/Alerts';
import loadAlertsBoxModule from '@/modules/AlertsBox';
import loadTranslationsProviderModule from '@/modules/TranslationsProviderModule';
import loadAccountModule from '@/modules/Account';
import loadNotificationsModule from '@/modules/Notifications';
import loadLanguageSelectorModule from '@/modules/LanguageSelector';
import loadLoginModule from '@/modules/Login';
import loadPage404Module from '@/modules/Page404';
import loadRegistrationModule from '@/modules/Registration';
import loadPasswordRecoveryModule from '@/modules/PasswordRecovery';
import loadDrawerModule from '@/modules/DrawerModule';
import loadHelmetModule from '@/modules/HelmetModule';
import loadWelcomeBackModule from '@/modules/WelcomeBack';
import loadOrderFormModule from '@/modules/OrderFormModule';
import loadEmployeeInviteFormModule from './modules/EmployeeInviteFormModule';
import loadGenericListModule from '@/modules/GenericList';
import ProfileView from '@/modules/ProfileView';
import loadThemeModeConfiguratorModule from '@/modules/ThemeModeConfiguratorModule';
import loadSearchModule from '@/modules/SearchModule';
import loadIntegrationsModule from '@/modules/IntegrationsModule';
import loadTelerouteIntegrationFormModule from '@/modules/TelerouteIntegrationFormModule';
import loadConfirmationModalModule from '@/modules/ConfirmationModalModule';
import loadIntegrationSaveActionModule from '@/modules/IntegrationSaveActionModule';
import loadOrderPublicationFormModule from '@/modules/OrderPublicationFormModule';
import loadTimoIntegrationFormModule from '@/modules/TimoIntegrationFormModule';
import loadMailReaderIntegrationFormModule from '@/modules/MailReaderIntegrationFormModule';
import loadOrderFromClipboardModule from '@/modules/OrderFromClipboardModule';
import loadClientsFormModule from '@/modules/ClientsFormModule';
import loadCompanyRegistrationService from '@/services/CompanyRegistrationService';
import loadCompanyRegistration from '@/modules/CompanyRegistration';
import loadOrdersFilters from '@/modules/OrdersFilters';
import loadClientsFilters from '@/modules/ClientsFilters';
import loadClientsDetailsModule from '@/modules/ClientsDetailsModule';
import loadAttachmentViewModule from '@/modules/AttachmentViewModule';
import loadSentryFeedback from '@/modules/SentryFeedback';
import loadIntegrationsLinkModule from '@/modules/IntegrationsLinkModule';
import loadSimpleLogoutButtonModule from '@/modules/SimpleLogoutButtonModule';
import loadExpiredPasswordRemindLinkModule from '@/modules/ExpiredPasswordRemindLinkModule';
import loadExpiredVerificationLinkModule from '@/modules/ExpiredVerificationLinkModule';

// services
import loadActionsService from '@/services/ActionsService';
import loadOutlookService from '@/services/OutlookService';
import loadFirewallService from '@/services/FirewallService';
import loadUserService from '@/services/UserService';
import loadLocationsService from '@/services/LocationsService';
import loadModalsService from '@/services/ModalsService';
import loadNotificationsService from '@/services/Notifications';
import loadTranslationsService from '@/services/TranslationsService';
import loadPersistStoreService from '@/services/PersistStore';
import loadDrawerService from '@/services/DrawerService';
import loadFirebaseService from '@/services/Firebase';
import loadFirebaseAuthenticationService from '@/services/FirebaseAuthentication';
import loadFirebaseRealtimeDatabaseService from '@/services/FirebaseRealtimeDatabase';
import loadIntegrationsService from '@/services/IntegrationsService';
import loadEmployeesService from '@/services/EmployeesService';
import loadClientsService from '@/services/ClientsService';
import loadTimezoneService from '@/services/TimezoneService';

// components
import loadClientComponent from '@/components/orders/Client';
import loadOrderPlaceComponent from '@/components/orders/Place';
import loadOrderRequirementsComponent from '@/components/orders/Requirements';
import loadOrderPriceComponent from '@/components/orders/Price';
import loadDayjsDateComponent from '@/components/DayjsDate';
import loadOrderStatusComponent from '@/components/orders/Status';
import loadOrderPublicationStatus from '@/components/orders/OrderPublicationStatus';
import loadResponsiblePersonComponent from '@/components/orders/ResponsiblePerson';
import loadClientNameComponent from '@/components/clientsList/ClientName';
import loadClientNipComponent from '@/components/clientsList/ClientNip';
import loadClientAddressComponent from '@/components/clientsList/ClientAddress';
import loadClientTypesComponent from '@/components/clientsList/ClientTypes';
import loadPhoneComponent from '@/components/clientsList/Phone';
import loadDropzoneComponent from '@/components/Dropzone';
import loadPhoneNumberComponent from '@/components/clientsList/PhoneNumber';
import loadIntegrationIconComponent from '@/components/IntegrationIcon';
import loadEmailComponent from '@/components/clientsList/Email';

// constants
import { API_BASE_URL, OFFICE_CONTACT_EMAIL } from '@/constants/urls';
import { firebaseConfig } from '@/constants/firebase';
import { THEME_ENV } from './constants/theme';

// config data
import ordersListModuleData from '@/configData/ordersListModule';
import offersListModuleData from '@/configData/offersListModule';
import loadOrdersService from './services/OrdersService';
import clientsListModuleData from './configData/clientsListModule';

// events
import {
  ORDERS_COPY,
  ORDERS_FROM_CLIPBOARD_OPEN,
  ORDERS_OPEN_FORM,
  ORDERS_OPEN_PUBLISH_FORM,
  ORDERS_OPEN_REPUBLISH_FORM,
  ORDERS_OPEN_DETAILS,
} from '@/eventrixEvents/orders';
import {
  INTEGRATIONS_MAIL_READER_EDIT_OPEN_MODAL,
  INTEGRATIONS_MAIL_READER_INTEGRATE_OPEN_MODAL,
  INTEGRATIONS_TELEROUTE_INTEGRATE_OPEN_MODAL,
  INTEGRATIONS_TIMO_INTEGRATE_OPEN_MODAL,
  INTEGRATIONS_TRANS_INTEGRATION_REQUIRED_MODAL_OPEN,
} from './eventrixEvents/integrations';
import { TMS_FIRETMS_INTEGRATE_OPEN_MODAL } from './eventrixEvents/tms';
import { CLIENTS_OPEN_DETAILS, CLIENTS_OPEN_FORM } from './eventrixEvents/clients';
import { ALERTS_SHOW_SUCCESS } from './eventrixEvents/alerts';
import employeesListModuleData from './configData/employeesListModule';
import loadEmployeesFilters from './modules/EmployeesFilters';
import loadDashboardModule from './modules/Dashboard';
import loadAwsFilesStorage from './services/FileService/AwsFilesStorage';
import loadFileService from './services/FileService';
import loadEmployeeNameComponent from './components/employeeList/EmployeeName';
import loadEmployeeRoleComponent from './components/employeeList/EmployeeRole';
import loadEffectivityAI from './modules/EffectivityAI';
import loadAiService from './services/AiService';
import loadEffectivityDetailsModule from './modules/EffectivityDetailsModule';
import { AI_EFFECTIVITY_OPEN_DETAILS } from './eventrixEvents/ai';
import loadEmployeeJoinedComponent from './components/employeeList/EmployeeJoined';
import loadWebsocketsService from './services/WebsocketsService';
import { EMPLOYEES_EDIT_FORM_OPEN, EMPLOYEES_INVITE_FORM_OPEN } from './eventrixEvents/employees';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '@/shared/helpers/supportedLanguages';
import loadFiretmsIntegrationFormModule from '@/modules/FiretmsIntegrationFormModule';
import loadTmsService from '@/services/TmsService';
import loadOffersService from '@/services/OffersService';
import { TranslationsServiceData } from '@/services/TranslationsService/TranslationsService';
import loadEmployeeEditFormModule from './modules/EmployeeEditFormModule';
import { DrawerModuleData } from './modules/DrawerModule/DrawerModule';
import loadOrderDetailsModule from './modules/OrderDetailsModule';
import loadIntegrationRequiredModalModule from './modules/IntegrationRequiredModalModule';
import loadElapsedTimeComponent from '@/components/Requests/ElapsedTime';
import loadSourceComponent from '@/components/Requests/Source';
import { OFFERS_OPEN_DETAILS } from '@/eventrixEvents/offers';
import loadOfferDetailsModule from './modules/OfferDetailsModule';
import loadOffersFilters from '@/modules/OffersFilters';
import loadOfferStatusComponent from '@/components/Requests/Status';
import loadOfferResponsiblePersonComponent from './components/Requests/ResponsiblePerson';

const config: ComposerConfig = {
  components: {
    client: {
      load: loadClientComponent,
      data: {},
    },
    requestsElapsedTime: {
      load: loadElapsedTimeComponent,
      data: {},
    },
    requestsSource: {
      load: loadSourceComponent,
      data: {},
    },
    offerStatus: {
      load: loadOfferStatusComponent,
      data: {},
    },
    orderPlace: {
      load: loadOrderPlaceComponent,
      data: {},
    },
    orderRequirements: {
      load: loadOrderRequirementsComponent,
      data: {},
    },
    orderPrice: {
      load: loadOrderPriceComponent,
      data: {},
    },
    dayjsDate: {
      load: loadDayjsDateComponent,
      data: {},
    },
    orderResponsiblePerson: {
      load: loadResponsiblePersonComponent,
      data: {},
    },
    offerResponsiblePerson: {
      load: loadOfferResponsiblePersonComponent,
      data: {},
    },
    orderStatus: {
      load: loadOrderStatusComponent,
      data: {},
    },
    orderPublicationStatus: {
      load: loadOrderPublicationStatus,
      data: {},
    },
    clientName: {
      load: loadClientNameComponent,
    },
    employeeName: {
      load: loadEmployeeNameComponent,
    },
    employeeRole: {
      load: loadEmployeeRoleComponent,
    },
    employeeJoined: {
      load: loadEmployeeJoinedComponent,
    },
    clientNip: {
      load: loadClientNipComponent,
    },
    email: {
      load: loadEmailComponent,
    },
    address: {
      load: loadClientAddressComponent,
    },
    phone: {
      load: loadPhoneComponent,
    },
    phoneNumber: {
      load: loadPhoneNumberComponent,
    },
    clientTypes: {
      load: loadClientTypesComponent,
    },
    dropzone: {
      load: loadDropzoneComponent,
    },
    integrationIcon: {
      load: loadIntegrationIconComponent,
    },
  },
  services: {
    actionsService: {
      dependencies: ['eventrixService'],
      load: loadActionsService,
    },
    firebase: {
      dependencies: [],
      load: loadFirebaseService,
      data: firebaseConfig,
    },
    authentication: {
      dependencies: [
        'ajaxService',
        'firebase',
        'eventrixService',
        'userService',
        'dictionariesService',
        'integrationsService',
      ],
      load: loadFirebaseAuthenticationService,
      events: ['App:init'],
      data: {
        userStatesNames: ['orders', 'notifications', 'integrations'],
      },
    },
    websocketsService: {
      dependencies: ['eventrixService'],
      load: loadWebsocketsService,
    },
    firebaseRealtimeDatabase: {
      dependencies: ['firebase', 'eventrixService'],
      load: loadFirebaseRealtimeDatabaseService,
    },
    modalsService: {
      dependencies: ['eventrixService'],
      load: loadModalsService,
    },
    drawerService: {
      dependencies: ['eventrixService'],
      load: loadDrawerService,
    },
    persistStore: {
      dependencies: ['eventrixService'],
      load: loadPersistStoreService,
      data: {
        whiteList: ['user', 'translations.selectedLanguage', 'themeMode', 'userSettings', 'dictionaries'],
      },
    },
    notificationsService: {
      dependencies: ['eventrixService', 'ajaxService'],
      load: loadNotificationsService,
      data: {
        url: '/mock-api/notifications.json',
      },
    },
    translationsService: {
      dependencies: ['eventrixService', 'ajaxService'],
      load: loadTranslationsService,
      data: {
        url: '/mock-api/translations.json',
        defaultLanguage: DEFAULT_LANGUAGE,
        languages: SUPPORTED_LANGUAGES,
      } satisfies TranslationsServiceData,
    },
    employeesService: {
      dependencies: ['eventrixService', 'ajaxService'],
      load: loadEmployeesService,
      data: {
        baseUrl: API_BASE_URL,
      },
    },
    ordersService: {
      dependencies: ['fileService', 'firebaseRealtimeDatabase', 'eventrixService', 'ajaxService'],
      load: loadOrdersService,
      data: {
        baseUrl: API_BASE_URL,
      },
    },
    clientsService: {
      dependencies: ['firebaseRealtimeDatabase', 'eventrixService', 'ajaxService'],
      load: loadClientsService,
      data: {
        baseUrl: API_BASE_URL,
      },
    },
    locationsService: {
      dependencies: ['ajaxService', 'eventrixService'],
      load: loadLocationsService,
      data: {
        baseUrl: API_BASE_URL,
      },
    },
    dictionariesService: {
      dependencies: ['eventrixService', 'persistStore', 'ajaxService'],
      load: loadDictionariesService,
      data: {
        baseUrl: API_BASE_URL,
      },
    },
    tmsService: {
      dependencies: ['eventrixService', 'ajaxService'],
      load: loadTmsService,
      data: {
        baseUrl: API_BASE_URL,
      },
    },
    offersService: {
      dependencies: ['eventrixService', 'ajaxService', 'fileService'],
      load: loadOffersService,
      data: {
        baseUrl: API_BASE_URL,
      },
    },
    userService: {
      dependencies: ['eventrixService', 'ajaxService', 'firebaseFilesStorage', 'firebaseRealtimeDatabase'],
      load: loadUserService,
      data: {
        baseUrl: API_BASE_URL,
      },
    },
    awsFilesStorage: {
      dependencies: ['eventrixService', 'ajaxService'],
      load: loadAwsFilesStorage,
      data: {
        baseUrl: API_BASE_URL,
      },
    },
    firebaseFilesStorage: {
      dependencies: ['firebase', 'eventrixService'],
      load: loadFirebaseFilesStorage,
    },
    fileService: {
      dependencies: ['firebaseFilesStorage', 'awsFilesStorage', 'eventrixService'],
      load: loadFileService,
    },
    outlookService: {
      dependencies: ['ajaxService', 'fileService', 'eventrixService'],
      load: loadOutlookService,
      data: {
        baseUrl: API_BASE_URL,
      },
    },
    firewallService: {
      dependencies: ['firebaseRealtimeDatabase', 'eventrixService'],
      load: loadFirewallService,
      data: {},
    },
    integrationsService: {
      dependencies: ['eventrixService', 'ajaxService'],
      load: loadIntegrationsService,
      data: {
        baseUrl: API_BASE_URL,
      },
    },

    companyRegistrationService: {
      dependencies: ['eventrixService', 'ajaxService'],
      load: loadCompanyRegistrationService,
      data: {
        baseUrl: API_BASE_URL,
      },
    },
    timezoneService: {
      dependencies: ['ajaxService'],
      load: loadTimezoneService,
      data: {},
    },
    aiService: {
      dependencies: ['eventrixService', 'ajaxService'],
      load: loadAiService,
      data: {
        effectivityBaseUrl: `${API_BASE_URL}/effectivity-ai`,
      },
    },
  },
  modules: {
    welcomeBackModule: {
      dependencies: [],
      load: loadWelcomeBackModule,
    },
    ordersListModule: {
      dependencies: [
        'eventrixService',
        'modalsService',
        'userService',
        'ordersService',
        'employeesService',
        'tmsService',
        'integrationsService',
        'dictionariesService',
      ],
      load: loadGenericListModule,
      data: ordersListModuleData,
    },
    offersListModule: {
      dependencies: ['eventrixService', 'offersService', 'ordersService', 'dictionariesService', 'employeesService'],
      load: loadGenericListModule,
      data: offersListModuleData,
    },
    clientsListModule: {
      dependencies: ['modalsService', 'clientsService', 'eventrixService'],
      load: loadGenericListModule,
      data: clientsListModuleData,
    },
    dashboardModule: {
      dependencies: ['eventrixService', 'offersService'],
      load: loadDashboardModule,
    },
    employeesListModule: {
      dependencies: ['modalsService', 'employeesService', 'eventrixService'],
      load: loadGenericListModule,
      data: employeesListModuleData,
    },
    logoModule: {
      dependencies: [],
      data: {
        logoUrl: `/assets/${THEME_ENV}/logo.svg`,
        darkLogoUrl: `/assets/${THEME_ENV}/logo.svg`,
      },
      load: loadLogoModule,
    },
    navigationLogoModule: {
      dependencies: [],
      data: {
        logoUrl: `/assets/${THEME_ENV}/logo.svg`,
        darkLogoUrl: `/assets/${THEME_ENV}/logo.svg`,
        title: '',
        styles: {
          alignItems: 'center',
          img: {
            maxWidth: 40,
            maxHeight: 40,
          },
        },
      },
      load: loadLogoModule,
    },
    rootModule: {
      dependencies: [],
      load: loadRootModule,
      data: {},
    },
    modalsModule: {
      dependencies: ['eventrixService', 'modalsService'],
      load: loadModalsModule,
      data: {},
    },
    drawerModule: {
      dependencies: ['eventrixService', 'drawerService'],
      load: loadDrawerModule,
      data: {
        side: 'right',
      } satisfies DrawerModuleData,
    },
    itemDetailsDrawerModule: {
      dependencies: ['eventrixService', 'drawerService'],
      load: loadDrawerModule,
      data: {
        side: 'right',
        variant: 'item-details',
      } satisfies DrawerModuleData,
    },
    themeProviderModule: {
      dependencies: [],
      load: loadThemeProviderModule,
      data: {},
    },
    loginModule: {
      dependencies: ['authentication', 'eventrixService'],
      load: loadLoginModule,
      data: {
        isSocialLoginAvailable: true,
        isPasswordRecoveryAvailable: true,
        isRegistrationAvailable: true,
        routes: {
          registration: '/register',
          forgotPassword: '/password-recovery',
          home: '/',
        },
        events: {
          login: 'Authentication:login',
          loginProvider: 'Authentication:loginProvider',
        },
      },
    },
    outlookLoginModule: {
      dependencies: ['authentication', 'eventrixService'],
      load: loadLoginModule,
      data: {
        isSocialLoginAvailable: true,
        routes: {
          registration: '/register',
          forgotPassword: '/password-recovery',
        },
        events: {
          login: 'Authentication:login',
          loginProvider: 'Authentication:loginProvider',
        },
      },
    },
    registrationModule: {
      dependencies: ['authentication', 'eventrixService'],
      load: loadRegistrationModule,
      data: {
        isSocialLoginAvailable: true,
        isPasswordRecoveryAvailable: true,
        isLoginLinkAvailable: true,
        routes: {
          login: '/login',
        },
        events: {
          register: 'Authentication:register',
          loginProvider: 'Authentication:loginProvider',
        },
      },
    },
    passwordRecoveryModule: {
      dependencies: ['eventrixService', 'authentication'],
      load: loadPasswordRecoveryModule,
      data: {
        isSocialLoginAvailable: true,
        isPasswordRecoveryAvailable: true,
        isLoginLinkAvailable: true,
        routes: {
          login: '/login',
        },
        events: {
          recovery: 'Authentication:passwordRecovery',
        },
      },
    },
    navigationModule: {
      dependencies: ['configService'],
      load: loadNavigationModule,
    },
    translationsProviderModule: {
      dependencies: ['translationsService', 'eventrixService'],
      load: loadTranslationsProviderModule,
    },
    alertsModule: {
      dependencies: ['eventrixService'],
      load: loadAlertsModule,
    },
    alertsBoxModule: {
      dependencies: ['eventrixService'],
      load: loadAlertsBoxModule,
    },
    accountModule: {
      dependencies: ['authentication'],
      load: loadAccountModule,
      data: {
        isLogoutAvailable: true,
        showLanguageSetting: true,
        showThemeSetting: true,
        events: {
          logout: 'Authentication:logout',
        },
        options: [
          {
            label: 'account.profile',
            href: '/profile',
            position: 'start',
          },
          {
            label: 'account.contact',
            href: `mailto:${OFFICE_CONTACT_EMAIL}`,
            position: 'end',
          },
        ],
      },
    },
    notificationsModule: {
      dependencies: ['notificationsService', 'eventrixService'],
      load: loadNotificationsModule,
    },
    languageSelectorModule: {
      dependencies: ['eventrixService', 'translationsService'],
      load: loadLanguageSelectorModule,
    },
    page404Module: {
      dependencies: [],
      load: loadPage404Module,
    },
    themeModeConfiguratorModule: {
      dependencies: [],
      load: loadThemeModeConfiguratorModule,
      data: {},
    },
    helmetModule: {
      dependencies: [],
      load: loadHelmetModule,
    },
    orderFormModule: {
      dependencies: [
        'modalsService',
        'eventrixService',
        'locationsService',
        'ordersService',
        'dictionariesService',
        'tmsService',
        'employeesService',
        'firebaseFilesStorage',
        'integrationsService',
        'clientsService',
      ],
      load: loadOrderFormModule as ResourceLoader,
      data: {},
      events: [ORDERS_OPEN_FORM, ORDERS_COPY],
    },
    employeeInviteFormModule: {
      dependencies: ['modalsService', 'employeesService', 'eventrixService'],
      load: loadEmployeeInviteFormModule as ResourceLoader,
      data: {},
      events: [EMPLOYEES_INVITE_FORM_OPEN],
    },
    employeeEditFormModule: {
      dependencies: ['modalsService', 'employeesService', 'eventrixService'],
      load: loadEmployeeEditFormModule as ResourceLoader,
      data: {},
      events: [EMPLOYEES_EDIT_FORM_OPEN],
    },
    profileModule: {
      dependencies: ['userService', 'eventrixService', 'locationsService', 'fileService'],
      load: ProfileView,
      data: {},
    },
    sendEmailModule: {
      dependencies: ['outlookService'],
      load: loadSendEmailModule,
      data: {},
    },
    OutlookWelcomeModule: {
      dependencies: ['outlookService'],
      load: loadOutlookWelcomeModule,
      data: {
        exampleData: 'exampleValue',
      },
    },
    searchModule: {
      dependencies: [],
      load: loadSearchModule,
      data: {
        exampleData: 'exampleValue',
      },
    },
    integrationsModule: {
      dependencies: ['eventrixService', 'integrationsService', 'tmsService', 'userService'],
      load: loadIntegrationsModule,
      data: {
        exampleData: 'exampleValue',
      },
    },
    integrationRequiredTransEuModalModule: {
      dependencies: ['modalsService', 'eventrixService'],
      load: loadIntegrationRequiredModalModule as ResourceLoader,
      events: [INTEGRATIONS_TRANS_INTEGRATION_REQUIRED_MODAL_OPEN],
    },
    integrationTelerouteFormModule: {
      dependencies: ['modalsService', 'eventrixService'],
      load: loadTelerouteIntegrationFormModule as ResourceLoader,
      data: {
        exampleData: 'exampleValue',
      },
      events: [INTEGRATIONS_TELEROUTE_INTEGRATE_OPEN_MODAL],
    },
    integrationTimoFormModule: {
      dependencies: ['modalsService', 'eventrixService'],
      load: loadTimoIntegrationFormModule as ResourceLoader,
      data: {
        exampleData: 'exampleValue',
      },
      events: [INTEGRATIONS_TIMO_INTEGRATE_OPEN_MODAL],
    },
    tmsFiretmsFormModule: {
      dependencies: ['modalsService', 'eventrixService'],
      load: loadFiretmsIntegrationFormModule as ResourceLoader,
      data: {
        exampleData: 'exampleValue',
      },
      events: [TMS_FIRETMS_INTEGRATE_OPEN_MODAL],
    },
    mailReaderIntegrationFormModule: {
      dependencies: ['modalsService', 'eventrixService', 'employeesService', 'ajaxService'],
      load: loadMailReaderIntegrationFormModule as ResourceLoader,
      data: {
        baseUrl: API_BASE_URL,
      },
      events: [INTEGRATIONS_MAIL_READER_INTEGRATE_OPEN_MODAL, INTEGRATIONS_MAIL_READER_EDIT_OPEN_MODAL],
    },
    integrationSaveActionModule: {
      dependencies: ['integrationsService', 'eventrixService'],
      load: loadIntegrationSaveActionModule,
    },
    confirmationModalModule: {
      dependencies: ['modalsService', 'eventrixService'],
      load: loadConfirmationModalModule as ResourceLoader,
      data: {
        exampleData: 'exampleValue',
      },
      events: ['Modal:confirmation.open'],
    },
    ordersFilters: {
      dependencies: [],
      load: loadOrdersFilters,
      data: {
        exampleData: 'exampleValue',
      },
    },
    offersFilters: {
      dependencies: [],
      load: loadOffersFilters,
      data: {},
    },
    clientsFilters: {
      dependencies: [],
      load: loadClientsFilters,
    },
    employeesFilters: {
      dependencies: ['employeesService'],
      load: loadEmployeesFilters,
    },
    companyRegistrationModule: {
      dependencies: ['companyRegistrationService', 'locationsService'],
      load: loadCompanyRegistration,
    },
    OrderPublicationFormModule: {
      dependencies: [
        'modalsService',
        'eventrixService',
        'locationsService',
        'ordersService',
        'dictionariesService',
        'tmsService',
        'employeesService',
        'firebaseFilesStorage',
        'integrationsService',
        'ajaxService',
      ],
      load: loadOrderPublicationFormModule as ResourceLoader,
      data: {},
      events: [ORDERS_OPEN_PUBLISH_FORM, ORDERS_OPEN_REPUBLISH_FORM],
    },
    ClientsFormModule: {
      dependencies: [
        'modalsService',
        'eventrixService',
        'dictionariesService',
        'tmsService',
        'clientsService',
        'locationsService',
        'employeesService',
      ],
      load: loadClientsFormModule as ResourceLoader,
      data: {},
      events: [CLIENTS_OPEN_FORM],
    },
    clientsDetailsModule: {
      dependencies: ['drawerService', 'eventrixService', 'dictionariesService', 'tmsService'],
      load: loadClientsDetailsModule as ResourceLoader,
      data: {},
      events: [CLIENTS_OPEN_DETAILS],
    },
    orderDetailsModule: {
      dependencies: ['drawerService', 'eventrixService', 'dictionariesService', 'tmsService', 'clientsService'],
      load: loadOrderDetailsModule as ResourceLoader,
      data: {},
      events: [ORDERS_OPEN_DETAILS],
    },
    offerDetailsModule: {
      dependencies: [
        'drawerService',
        'eventrixService',
        'dictionariesService',
        'tmsService',
        'clientsService',
        'offersService',
        'ajaxService',
      ],
      load: loadOfferDetailsModule as ResourceLoader,
      data: {},
      events: [OFFERS_OPEN_DETAILS],
    },
    attachmentViewModule: {
      dependencies: ['eventrixService', 'firebaseFilesStorage', 'translationsService'],
      load: loadAttachmentViewModule,
      data: {},
    },
    OrderFromClipboardModule: {
      dependencies: ['modalsService', 'eventrixService'],
      load: loadOrderFromClipboardModule as ResourceLoader,
      data: {},
      events: [ORDERS_FROM_CLIPBOARD_OPEN],
    },
    SentryFeedback: {
      dependencies: [],
      load: loadSentryFeedback,
      data: {
        successAlertEvent: ALERTS_SHOW_SUCCESS,
      },
    },
    IntegrationsLinkModule: {
      dependencies: [],
      load: loadIntegrationsLinkModule,
      data: {},
    },
    ExpiredVerificationLinkModule: {
      dependencies: [],
      load: loadExpiredVerificationLinkModule,
      data: {},
    },
    ExpiredPasswordRemindLinkModule: {
      dependencies: [],
      load: loadExpiredPasswordRemindLinkModule,
      data: {},
    },
    SimpleLogoutButtonModule: {
      dependencies: [],
      load: loadSimpleLogoutButtonModule,
      data: {
        exampleData: 'exampleValue',
      },
    },
    EffectivityAI: {
      dependencies: ['eventrixService', 'aiService', 'ordersService'],
      load: loadEffectivityAI,
      data: {},
    },
    EffectivityDetailsModule: {
      dependencies: ['modalsService', 'eventrixService', 'aiService'],
      load: loadEffectivityDetailsModule as ResourceLoader,
      events: [AI_EFFECTIVITY_OPEN_DETAILS],
      data: {},
    },
  },
  layouts: {
    basic: {
      dependencies: [
        'firewallService',
        'authentication',
        'persistStore',
        'configService',
        'companyRegistrationService',
        'actionsService',
        'timezoneService',
        'websocketsService',
        'translationsService',
        'dictionariesService',
      ],
      load: loadBasicLayout,
      regions: {
        leftnav: {
          modules: ['navigationLogoModule', 'navigationModule', 'alertsModule'],
        },
        header: {
          modules: ['notificationsModule', 'IntegrationsLinkModule', 'accountModule', 'helmetModule'],
        },
        main: {
          modules: [],
        },
      },
    },
    outlook: {
      dependencies: [
        'outlookService',
        'authentication',
        'persistStore',
        'eventrixService',
        'timezoneService',
        'websocketsService',
      ],
      load: loadOutlookLayout,
      regions: {
        header: {
          modules: [],
        },
        main: {
          modules: [],
        },
      },
    },
    login: {
      dependencies: ['persistStore', 'timezoneService', 'websocketsService'],
      load: loadLoginLayout,
      regions: {
        header: {
          modules: ['languageSelectorModule', 'helmetModule'],
        },
        leftbar: {
          modules: ['welcomeBackModule'],
        },
        main: {
          modules: [],
        },
      },
    },
    fullView: {
      dependencies: [
        'firewallService',
        'authentication',
        'persistStore',
        'configService',
        'timezoneService',
        'websocketsService',
      ],
      load: loadFullViewLayout,
      regions: {
        header: {
          modules: ['languageSelectorModule', 'helmetModule'],
        },
        main: {
          modules: [],
        },
        footer: {
          modules: [],
        },
      },
    },
  },
  routes: {
    dashboard: {
      path: '/dashboard',
      layout: 'basic',
      title: 'Dashboard',
      regions: {
        main: {
          modules: ['dashboardModule'],
        },
      },
      data: {
        isProtected: true,
        icon: 'fluent:home-20-regular',
        menuLabel: 'menu.dashboard',
        featureFlag: 'dashboard',
      },
    },
    orders: {
      path: '/',
      layout: 'basic',
      title: 'Orders',
      regions: {
        main: {
          modules: ['ordersListModule'],
        },
      },
      data: {
        isProtected: true,
        icon: 'fluent:text-bullet-list-square-clock-20-regular',
        menuLabel: 'menu.orders',
      },
    },
    offers: {
      path: '/offers',
      layout: 'basic',
      title: 'Offers',
      regions: {
        main: {
          modules: ['offersListModule'],
        },
      },
      data: {
        isProtected: true,
        icon: 'solar:chat-round-money-bold',
        menuLabel: 'menu.offers',
        featureFlag: 'offers',
      },
    },
    clients: {
      path: '/clients',
      layout: 'basic',
      title: 'Clients',
      regions: {
        main: {
          modules: ['clientsListModule'],
        },
      },
      data: {
        isProtected: true,
        icon: 'ooui:user-contributions-ltr',
        menuLabel: 'menu.clients',
      },
    },
    effectivityAI: {
      path: '/effectivity-ai',
      layout: 'basic',
      title: 'Effectivity AI',
      regions: {
        main: {
          modules: ['EffectivityAI'],
        },
      },
      data: {
        isProtected: true,
        icon: 'ph:open-ai-logo-fill',
        menuLabel: 'menu.effectivityAI',
        featureFlag: 'effectivity_ai',
      },
    },
    login: {
      path: '/login',
      layout: 'login',
      title: 'Login',
      regions: {
        main: {
          modules: ['loginModule'],
        },
      },
      data: {},
    },
    expiredVerificationLink: {
      path: '/expired-verification-link',
      layout: 'fullView',
      title: 'Expired verification link',
      regions: {
        main: {
          modules: ['ExpiredVerificationLinkModule'],
        },
        header: {
          modules: ['SimpleLogoutButtonModule'],
        },
      },
    },
    expiredPasswordRemindLink: {
      path: '/expired-remind-password-link',
      layout: 'fullView',
      title: 'Expired password remind link',
      regions: {
        main: {
          modules: ['ExpiredPasswordRemindLinkModule'],
        },
        header: {
          modules: ['SimpleLogoutButtonModule'],
        },
      },
    },
    outlookLogin: {
      path: '/outlook-login',
      layout: 'outlook',
      title: 'Login',
      regions: {
        header: {
          modules: ['languageSelectorModule', 'helmetModule'],
        },
        main: {
          modules: ['outlookLoginModule', 'OutlookWelcomeModule'],
        },
      },
      data: {},
    },
    outlookWelcome: {
      path: '/outlook-logged-in',
      layout: 'outlook',
      title: 'Welcome',
      regions: {
        header: {
          modules: ['languageSelectorModule', 'helmetModule'],
        },
        main: {
          modules: ['OutlookWelcomeModule'],
        },
      },
      data: {},
    },
    outlookSendEmail: {
      path: '/outlook-send-email',
      layout: 'outlook',
      title: 'send email',
      regions: {
        main: {
          modules: ['sendEmailModule'],
        },
      },
      data: {},
    },
    registration: {
      path: '/register',
      layout: 'login',
      title: 'Users list',
      regions: {
        main: {
          modules: ['registrationModule'],
        },
      },
      data: {},
    },
    passwordRecovery: {
      path: '/password-recovery',
      layout: 'fullView',
      title: 'Users list',
      regions: {
        main: {
          modules: ['passwordRecoveryModule'],
        },
      },
      data: {},
    },
    profile: {
      path: '/profile',
      layout: 'basic',
      title: 'Profile',
      regions: {
        main: {
          modules: ['profileModule'],
        },
      },
      data: {
        isProtected: true,
      },
    },
    integrations: {
      path: '/integrations',
      layout: 'basic',
      title: 'Integrations',
      regions: {
        main: {
          modules: ['integrationsModule'],
        },
      },
      data: {
        isProtected: true,
      },
    },
    integrationsSaveToken: {
      path: '/integrations-confirmation/:integrationId',
      layout: 'fullView',
      title: 'Integrations',
      regions: {
        main: {
          modules: ['integrationSaveActionModule'],
        },
      },
      data: {
        isProtected: true,
      },
    },
    page404: {
      path: '/*',
      layout: 'fullView',
      title: 'Users list',
      regions: {
        main: {
          modules: ['page404Module'],
        },
      },
      data: {},
    },
  },
  settings: {},
};
export default config;
