import { JSONable } from '@frontend-composer/core';
import { EMPLOYEES_EDIT_FORM_OPEN, EMPLOYEES_FETCH_LIST, EMPLOYEES_INVITE_FORM_OPEN } from '@/eventrixEvents/employees';
import { EmployeeRole } from '@/types/employees';
import { EMPLOYEES_BLOCK, EMPLOYEES_UNBLOCK } from '@/eventrixEvents/employees';
import { GenericListModuleData } from '@/modules/GenericList/types';

const employeesListModuleData: JSONable = {
  title: 'employees',
  baseStateName: 'employees',
  events: {
    loadList: EMPLOYEES_FETCH_LIST,
  },
  translationContext: 'employees',
  hideHeader: false,
  limit: 20,
  headerActions: [
    {
      text: 'inviteNewUsers',
      title: 'inviteNewUsers',
      iconName: 'eva:plus-outline',
      eventName: EMPLOYEES_INVITE_FORM_OPEN,
      isAccessibleFor: [EmployeeRole.ADMIN],
    },
  ],
  listColumns: [
    {
      id: 'name',
      title: 'name',
      key: 'name',
      component: 'employeeName',
      sx: {
        width: '100%',
      },
    },
    {
      id: 'role',
      title: 'role',
      key: 'role',
      component: 'employeeRole',
      sx: {
        maxWidth: 260,
        minWidth: 260,
      },
    },
    {
      id: 'phone',
      title: 'phone',
      key: 'phone',
      component: 'phone',
      sx: {
        maxWidth: 260,
        minWidth: 260,
      },
    },
    {
      id: 'email',
      title: 'email',
      key: 'email',
      sx: {
        maxWidth: 260,
        minWidth: 260,
      },
    },
    {
      id: 'employeeJoined',
      title: 'joined',
      key: 'employeeJoined',
      component: 'employeeJoined',
      align: 'center',
      sx: {
        display: 'flex',
        justifyContent: 'center',
        sx: {
          maxWidth: 260,
          minWidth: 260,
        },
      },
    },
  ],
  actionsColumnWidth: 74,
  listItemActions: [
    {
      iconName: '/assets/icons/edit.svg',
      title: 'editUser',
      eventName: EMPLOYEES_EDIT_FORM_OPEN,
      auth: {
        isOwner: {
          $eq: true,
        },
      },
      when: {
        isOwner: {
          $ne: true,
        },
        status: {
          $eq: 'active',
        },
      },
    },
    {
      iconName: '/assets/icons/edit.svg',
      title: 'editUser',
      eventName: EMPLOYEES_EDIT_FORM_OPEN,
      auth: {
        role: {
          $eq: EmployeeRole.ADMIN,
        },
        isOwner: {
          $eq: false,
        },
      },
      when: {
        isOwner: {
          $ne: true,
        },
        status: {
          $eq: 'active',
        },
      },
    },
    {
      iconName: 'mdi:block',
      title: 'block',
      eventName: EMPLOYEES_BLOCK,
      auth: {
        isOwner: {
          $eq: true,
        },
      },
      when: {
        status: {
          $eq: 'active',
        },
        isOwner: {
          $ne: true,
        },
        id: {
          $ne: 'user.id',
        },
      },
    },
    {
      iconName: 'mdi:block',
      title: 'block',
      eventName: EMPLOYEES_BLOCK,
      auth: {
        role: {
          $eq: EmployeeRole.ADMIN,
        },
        isOwner: {
          $eq: false,
        },
      },
      when: {
        status: {
          $eq: 'active',
        },
        isOwner: {
          $ne: true,
        },
        id: {
          $ne: 'user.id',
        },
      },
    },
    {
      iconName: 'mdi:account-reactivate-outline',
      title: 'unblock',
      eventName: EMPLOYEES_UNBLOCK,
      auth: {
        isOwner: {
          $eq: true,
        },
      },
      when: {
        status: {
          $eq: 'blocked',
        },
        isOwner: {
          $ne: true,
        },
        id: {
          $ne: 'user.id',
        },
      },
    },
    {
      iconName: 'mdi:account-reactivate-outline',
      title: 'unblock',
      eventName: EMPLOYEES_UNBLOCK,
      auth: {
        role: {
          $eq: EmployeeRole.ADMIN,
        },
        isOwner: {
          $eq: false,
        },
      },
      when: {
        status: {
          $eq: 'blocked',
        },
        isOwner: {
          $ne: true,
        },
        id: {
          $ne: 'user.id',
        },
      },
    },
  ],
  listMainActions: [],
  mergeActionsToDropdown: true,
  tablePaddingBottom: 384,
} satisfies GenericListModuleData;

export default employeesListModuleData;
