export const OFFERS_OPEN_DETAILS = 'Offers:open.details';
export const OFFERS_FETCH_LIST = 'Offers:fetch.list';
export const OFFERS_REFRESH_LIST = 'Offers:refresh.list';
export const OFFERS_FETCH_STATUSES = 'Offers:fetch.statuses';
export const OFFERS_UPDATE_FILTERS = 'Offers:update.filters';
export const OFFERS_FETCH_FILTERS = 'Offers:fetch.filters';
export const OFFERS_SEARCH = 'Offers:search';
export const OFFERS_FETCH_STATS = 'Offers:fetch.stats';

export const OFFERS_TOGGLE_SELECTED_ROW = 'Offers:toggle.selected.row';
export const OFFERS_SET_PAGE = 'Offers:set.page';
export const OFFERS_BULK_PUBLISH = 'Offers:bulk.publish';
export const OFFERS_UPLOAD_FILE = 'Offers:upload.file';
export const OFFERS_UPDATE = 'Offers:update';
