export const INTEGRATIONS_SAVE_TOKEN = 'Integrations.save.token';
export const INTEGRATIONS_FETCH_LIST = 'Integrations.fetch.list';

export const INTEGRATIONS_TELEROUTE_INTEGRATE_OPEN_MODAL = 'Integrations:teleroute.open';
export const INTEGRATIONS_TELEROUTE_INTEGRATE = 'Integrations:teleroute.integrate';
export const INTEGRATIONS_TELEROUTE_SUCCESS = 'Integrations:teleroute.success';
export const INTEGRATIONS_TELEROUTE_REMOVE = 'Integrations:teleroute.remove';

export const INTEGRATIONS_TIMO_INTEGRATE_OPEN_MODAL = 'Integrations:timo.open';
export const INTEGRATIONS_TIMO_INTEGRATE = 'Integrations:timo.integrate';
export const INTEGRATIONS_TIMO_SUCCESS = 'Integrations:timo.success';
export const INTEGRATIONS_TIMO_REMOVE = 'Integrations:timo.remove';

export const INTEGRATIONS_TRANS_INTEGRATE_OPEN_MODAL = 'Integrations:trans.open';
export const INTEGRATIONS_TRANS_INTEGRATE = 'Integrations:trans.integrate';
export const INTEGRATIONS_TRANS_SUCCESS = 'Integrations:trans.success';
export const INTEGRATIONS_TRANS_REMOVE = 'Integrations:trans.remove';
export const INTEGRATIONS_TRANS_INTEGRATION_REQUIRED_MODAL_OPEN = 'Integrations:trans.integration.required.modal.open';

export const INTEGRATIONS_MAIL_READER_INTEGRATE_OPEN_MODAL = 'Integrations:mail-reader.integrate.open.modal';
export const INTEGRATIONS_MAIL_READER_EDIT_OPEN_MODAL = 'Integrations:mail-reader.edit.open.modal';
export const INTEGRATIONS_MAIL_READER_INTEGRATE_SUCCESS = 'Integrations:mail-reader.integrate.success';
export const INTEGRATIONS_MAIL_READER_FETCH_CONFIGURATION = 'Integrations:mail-reader.fetch-configuration';
export const INTEGRATIONS_MAIL_READER_REMOVE_INTEGRATE = 'Integrations:mail-reader.remove.integration';
export const INTEGRATIONS_MAIL_READER_FETCH_EMAILS = 'Integrations:mail-reader.fetch.emails';
export const INTEGRATIONS_MAIL_READER_ERROR = 'Integrations:mail-reader.error';

export const INTEGRATIONS_OPEN_WINDOW = 'Integrations.window.open';
export const INTEGRATIONS_CLOSE_WINDOW = 'Integrations.window.close';
