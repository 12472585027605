export const ORDERS_CREATE = 'Orders:create';
export const ORDERS_REFRESH_ORDER = 'Orders:refresh.order';
export const ORDERS_RECEIVE_ORDER = 'Orders:receive:order';
export const ORDERS_REMOVE_DRAFT = 'Orders:remove:draft';
export const ORDERS_REMOVE_FROM_LIST = 'Orders:remove:from.list';
export const ORDERS_SUBSCRIBE_ON_LIST = 'Orders:subscribe.on.list';
export const ORDERS_SEARCH = 'Orders:search';
export const ORDERS_REPROCESS_FILE = 'Orders:reprocess.file';
export const ORDERS_REMOVE_FILE = 'Orders:remove.file';
export const ORDERS_OPEN_FORM = 'Orders:form.open';
export const ORDERS_FROM_CLIPBOARD_OPEN = 'Orders:from.clipboard.open';
export const ORDERS_OPEN_DETAILS = 'Orders:details.open';

export const ORDERS_OPEN_PUBLISH_FORM = 'Orders:publishForm.open';
export const ORDERS_OPEN_REPUBLISH_FORM = 'Orders:republishForm.open';
export const ORDERS_PUBLISH = 'Orders:publish';
export const ORDERS_REPUBLISH = 'Orders:republish';

export const ORDERS_UPDATE = 'Orders:update';
export const ORDERS_REMOVE = 'Orders:remove';
export const ORDERS_FETCH_LIST = 'Orders:fetch.list';
export const ORDERS_START_FETCHING_LIST = 'Orders:start.fetching.list';
export const ORDERS_FETCH_FILTERS = 'Orders:fetch.filters';
export const ORDERS_FETCH_STATUSES = 'Orders:fetch.statuses';
export const ORDERS_FETCH_STATS = 'Orders:fetch.stats';
export const ORDERS_FETCH_CARRIER_DATA = 'Orders:fetch.carrier.data';
export const ORDERS_UPDATE_FILTERS = 'Orders:update.filters';
export const ORDERS_SET_PAGE = 'Orders:set.page';
export const ORDERS_FETCH_DETAILS = 'Orders:fetch.details';
export const ORDERS_FETCH_USER_MAIL = 'Orders:fetch.user.mail';
export const ORDERS_SEND_TO_TMS = 'Orders:send.to.tms';
export const ORDERS_COPY = 'Orders:copy';
export const ORDERS_SET_SELECTED_ROW = 'Orders:set.selected.row';
export const ORDERS_TOGGLE_SELECTED_ROW = 'Orders:toggle.selected.row';
export const ORDERS_UNSELECT_ROW = 'Orders:unselect.row';
export const ORDERS_REMOVE_FROM_EXCHANGE = 'Orders:remove.from.exchange';
export const ORDERS_UPLOAD_FILE = 'Orders:upload.file';
export const ORDERS_EXPORT_SELECTED_TO_CSV = 'Orders:export.selected.to.csv';

export const ALERTS_SHOW_SUCCESS = 'Alerts:show.success';

// WEBSOCKETS
export const ORDERS_CREATED = 'Orders:created';
export const ORDERS_REMOVED = 'Orders:removed';
export const ORDERS_UPDATED = 'Orders:updated';
