export const EMPLOYEES_FETCH_LIST = 'Employees:fetch.list';
export const EMPLOYEES_SEARCH = 'Employees:search';
export const EMPLOYEES_APPROVE = 'Employees:approve';
export const EMPLOYEES_DENY = 'Employees:deny';
export const EMPLOYEES_INVITE_FORM_OPEN = 'Employees:invite.form.open';
export const EMPLOYEES_INVITE = 'Employees:invite';
export const EMPLOYEES_EDIT_SAVE = 'Employees:edit.save';
export const EMPLOYEES_EDIT_FORM_OPEN = 'Employees.edit.modal.open';
export const EMPLOYEES_BLOCK = 'Employees:block';
export const EMPLOYEES_UNBLOCK = 'Employees:unblock';
