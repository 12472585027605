import { NumberWithPrefix } from './contacts';
import { Company, UserStatus } from './user';

export enum EmployeeRole {
  ADMIN = 'ADMIN',
  ACCOUNTING = 'ACCOUNTING',
  FORWARDER = 'FORWARDER',
  SELLER = 'SELLER',
}

export type EmployeeFromApi = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone?: NumberWithPrefix | null;
  picture: string;
  role: EmployeeRole;
  isOwner: boolean;
  company: Company;
  status: UserStatus;
  joinedAt: string | null;
};

export type Employee = EmployeeFromApi;

export type EmployeeToApi = Employee & {
  setAsCompanyOwner: boolean;
};
export type EmployeeInviteFormValues = Pick<Employee, 'email'>;
